import React from 'react'
import Blogs from '../Components/Blogs'

const Blog = () => {
  return (
    <>
      <Blogs/>
    </>
  )
}

export default Blog
